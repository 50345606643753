import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeTypeEntity } from "domain/entity/ChargeType/ChargeTypeEntity";
import { INITIAL_CHARGE_TYPE_COL_DEF } from "presentation/constant/ChargeType/ChargeTypeMaintenanceColumnDefinition";
import { ChargeTypeMaintenanceConstant } from "presentation/constant/ChargeType/ChargeTypeMaintenanceConstant";
import { useChargeTypeMaintenanceVM } from "presentation/hook/ChargeType/useChargeTypeMaintenanceVM";
import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
const ChargeTypeTablePanel: React.FC = () => {
    const [chargeTypeState] = useChargeTypeMaintenanceTracked();
    const chargeTypeVM = useChargeTypeMaintenanceVM();
    const gridRef: any = useRef(null);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        chargeTypeVM.updateSelectedRows(selectedRows);
    }, [chargeTypeVM])

    const handleAddClick = useCallback(() => {
        chargeTypeVM.onAdd();
    }, [chargeTypeVM])

    const handleRowDoubleClick = useCallback((entity: ChargeTypeEntity) => {
        chargeTypeVM.onEdit(entity);
    }, [chargeTypeVM])

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='tariff-code-table'
                headerLabel={ChargeTypeMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_CHARGE_TYPE_COL_DEF}
                data={chargeTypeState.tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: ChargeTypeEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [chargeTypeState.tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])
    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}
export default memo(ChargeTypeTablePanel); 
